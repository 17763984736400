import axios from 'axios';

const HOST = 'https://api.gastronaut.ai/codeGen';

const getTokenData = async (restaurantId, token) => {
  const URL = `${HOST}/access/${restaurantId}/${token}`;

  const { data } = await axios.get(URL);

  return data;
};

const createCode = async (restaurantId, token, title) => {
  const URL = `${HOST}/${restaurantId}`;

  const { data } = await axios.post(URL, { token, title });

  return data;
};

const devalueCode = async (restaurantId, code) => {
  const URL = `${HOST}/${restaurantId}/${code}/devalue`;

  const { data } = await axios.get(URL);

  return data;
};

const getCodeData = async (restaurantId, code) => {
  const URL = `${HOST}/${restaurantId}/${code}`;

  const { data } = await axios.get(URL);

  return data;
};

export { getTokenData, createCode, getCodeData, devalueCode };
