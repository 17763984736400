import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Typography, Button } from '@material-ui/core';
import { getCodeData, devalueCode } from '../API';

const CreateCodeForm = ({ match }) => {
  const { restaurantId, code } = match.params;
  const [data, setdata] = useState({ loading: true });

  useEffect(() => {
    (async () => {
      try {
        setdata({ loading: true });

        let data = await getCodeData(restaurantId, code);

        setdata(data);
      } catch (error) {
        setdata({ error: error.message });
      }
    })();
  }, [restaurantId, code]);

  const devalue = async () => {
    try {
      let confirm = window.confirm(
        'Wollen sie diesen Code wirklich entwerten? Dieser verliert danach seine Gültigkeit'
      );

      if (!confirm) {
        return;
      }

      setdata({ ...data, codeLoading: true });

      await devalueCode(restaurantId, code);

      setdata({ ...data, valid: false });
    } catch (error) {
      setdata({ ...data, valid: false });
    }
  };

  return (
    <div className="container">
      {data.loading && <CircularProgress />}
      {data.error && (
        <Typography color="error" variant="h5" style={{ textAlign: 'center' }}>
          {data.error}
        </Typography>
      )}

      {data.valid && (
        <Box className="inner">
          {data.logo && <img alt="Logo" src={data.logo} />}
          <Typography
            variant="h5"
            style={{ marginBottom: 20, textAlign: 'center' }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="h5"
            style={{ marginBottom: 20, textAlign: 'center' }}
          >
            {data.title}
          </Typography>

          <Button onClick={devalue} variant="outlined">
            Entwerten
          </Button>
        </Box>
      )}

      {!data.loading && !data.error && !data.valid && (
        <Box className="inner">
          {data.logo && <img alt="Logo" src={data.logo} />}
          <Typography
            variant="h5"
            style={{ marginBottom: 20, textAlign: 'center' }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="h5"
            style={{ marginBottom: 20, textAlign: 'center' }}
          >
            {data.title}
          </Typography>

          <Typography variant="h5" color="error">
            Entwertet
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default CreateCodeForm;
