import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Typography,
  Button,
  TextField
} from '@material-ui/core';
import { getTokenData, createCode } from '../API';

const CreateCodeForm = ({ match }) => {
  const { restaurantId, token } = match.params;
  const [data, setdata] = useState({ loading: true });
  const [url, seturl] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setdata({ loading: true });

        let data = await getTokenData(restaurantId, token);

        setdata(data);
      } catch (error) {
        setdata({ error: error.message });
      }
    })();
  }, [restaurantId, token]);

  const submit = async () => {
    try {
      setdata({ ...data, codeLoading: true, copySuccess: '' });

      let { url } = await createCode(restaurantId, token, data.title);

      setdata({
        ...data,
        tokensCreated: data.tokensCreated + 1,
        codeLoading: false,
        codeError: false,
        copySuccess: ''
      });

      seturl(url);
    } catch (error) {
      setdata({ ...data, codeLoading: false, codeError: error.message });
    }
  };

  const copyToClipboard = e => {
    var copyText = document.getElementById('url');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    e.target.focus();
    setdata({ ...data, copySuccess: 'Copied!' });
  };

  return (
    <div className="container">
      {data.loading && <CircularProgress />}
      {data.error && (
        <Typography color="error" variant="h5" style={{ textAlign: 'center' }}>
          {data.error}
        </Typography>
      )}

      {data.token && (
        <Paper className="inner">
          {url && (
            <Typography onClick={copyToClipboard} style={{ marginBottom: 10 }}>
              <span id="url">{url}</span>
              <sup style={{ marginLeft: 2 }}>{data.copySuccess}</sup>
            </Typography>
          )}
          {data.validFor && (
            <Typography variant="h5">
              {data.tokensCreated} / {data.validFor}
            </Typography>
          )}
          <TextField
            label="Titel"
            value={data.title}
            onChange={e => setdata({ ...data, title: e.target.value })}
          />
          <Button onClick={submit}> Neuen Link erstellen</Button>
        </Paper>
      )}
    </div>
  );
};

export default CreateCodeForm;
