import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CreateCodeForm from './Components/CreateCodeForm';
import Code from './Components/Code';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            path="/access/:restaurantId/:token"
            component={CreateCodeForm}
            exact={true}
          />
          <Route path="/:restaurantId/:code" component={Code} exact={true} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
